//utils
import { getStorage, setStorage } from "../../utils/Storage";
import { validateCPF } from "../../utils/String";
import { isEqual } from "../../utils/Array";
import { isEmail } from "validator";

const hasErrors = (surveyid) => {
  const surveyData = getStorage(surveyid, true);
  let { survey, user } = surveyData;
  let errorsLocal = {};
  const questions = survey.pages[user.currentPage].questions;

  questions.forEach((question) => {
    if (question.type === "cascading-dropdown") {
      if (
        question.require &&
        question.active &&
        (!user.responses?.[question.id] ||
          user.responses?.[question.id].length !==
            question.options.titles[survey.language].length)
      ) {
        errorsLocal[question.id] = "Esta questão é obrigatória";
      }
    } else if (question.type === "input" && question?.properties?.email) {
      if (
        question.require &&
        question.active &&
        !user.responses?.[question.id]
      ) {
        errorsLocal[question.id] = "Esta questão é obrigatória";
      }

      if (
        user.responses?.[question.id] &&
        !isEmail(user.responses[question.id])
      ) {
        errorsLocal[question.id] = "Email inválido. Digite um endereço válido.";
      }
    } else if (question.type === "input" && question?.properties?.cpf) {
      if (
        question.require &&
        question.active &&
        !user.responses?.[question.id]
      ) {
        errorsLocal[question.id] = "Esta questão é obrigatória";
      }

      if (
        user.responses?.[question.id] &&
        !validateCPF(user.responses[question.id])
      ) {
        errorsLocal[question.id] = "CPF inválido.";
      }
    } else if (["radiobox", "checkbox"].includes(question.type)) {
      if (
        question.require &&
        question.active &&
        !user.responses?.[question.id]
      ) {
        errorsLocal[question.id] = "Esta questão é obrigatória";
      }

      //validando options com opção Outros|Others
      question.options.forEach((o) => {
        if (
          o?.properties &&
          o.properties?.type === "other" &&
          o.properties?.require
        ) {
          const hasReposnse = user.responses?.[question.id];

          if (hasReposnse && hasReposnse.toString().indexOf("||") >= 0) {
            //if string
            if (typeof hasReposnse === "string") {
              const splitOther = hasReposnse.split("||");
              if (
                splitOther[1] === "" &&
                o.label[surveyData.survey.language] === splitOther[0]
              ) {
                errorsLocal[o.id] = "Este campo é obrigatório";
              }
            } else {
              //if array
              hasReposnse.forEach((r) => {
                const splitOther = r.split("||");
                if (
                  splitOther[1] === "" &&
                  o.label[surveyData.survey.language] === splitOther[0]
                ) {
                  errorsLocal[o.id] = "Este campo é obrigatório";
                }
              });
            }
          }
        }
      });
    } else if (
      question.require &&
      question.active &&
      !user.responses?.[question.id]
    ) {
      errorsLocal[question.id] = "Esta questão é obrigatória";
    }
  });

  setStorage(
    surveyid,
    {
      ...surveyData,
      errors: Object.keys(errorsLocal).length > 0 ? errorsLocal : "",
    },
    true
  );
};

const getTags = (surveyid, txt) => {
  txt = hasURLTags(surveyid, txt);
  txt = hasQuestionValue(surveyid, txt);
  txt = hasContactTags(surveyid, txt);
  return txt;
};

const hasURLTags = (surveyid, textContent) => {
  const surveyData = getStorage(surveyid, true);

  //Mapeia tags de URL
  const regex = /{{url\['[a-z]*'\]}}/gm;
  let searchUrlTag = textContent.matchAll(regex);

  for (let urlTag of searchUrlTag) {
    let removeInitTag = urlTag[0].replace("{{url['", "");
    let removeEndTag = removeInitTag.replace("']}}", "");

    const urlValue = surveyData.user.url[removeEndTag];
    if (
      urlValue !== null &&
      urlValue !== "" &&
      urlValue !== "undefined" &&
      urlValue !== undefined
    ) {
      textContent = textContent.replace(urlTag[0], urlValue);
    } else {
      textContent = textContent.replace(urlTag[0], "");
    }
  }

  return textContent;
};

export const hasQuestionValue = (surveyid, textContent) => {
  const surveyData = getStorage(surveyid, true);
  //Mapeia tags de Questoes
  const regex = /{{question\[[0-9]*\]}}/gm;
  let searchQuestionTag = textContent.matchAll(regex);

  for (let questionsTag of searchQuestionTag) {
    let removeInitTag = questionsTag[0].replace("{{question[", "");
    let removeEndTag = removeInitTag.replace("]}}", "");
    const responseValue = surveyData.user.responses[removeEndTag];
    if (
      responseValue !== null &&
      responseValue !== "" &&
      responseValue !== "undefined" &&
      responseValue !== undefined
    ) {
      textContent = textContent.replace(questionsTag[0], responseValue);
    } else {
      textContent = textContent.replace(questionsTag[0], "");
    }
  }

  return textContent;
};

const hasContactTags = (surveyid, textContent) => {
  const surveyData = getStorage(surveyid, true);

  //Mapeia tags de contato
  const regex = /{{contact\['[a-z]*'\]}}/gm;
  let searchContactTags = textContent.matchAll(regex);

  for (let contactTag of searchContactTags) {
    let removeInitTag = contactTag[0].replace("{{contact['", "");
    let removeEndTag = removeInitTag.replace("']}}", "");
    const contactValue = surveyData.user.contact[removeEndTag];
    if (
      contactValue !== null &&
      contactValue !== "" &&
      contactValue !== "undefined" &&
      contactValue !== undefined
    ) {
      textContent = textContent.replace(contactTag[0], contactValue);
    } else {
      textContent = textContent.replace(contactTag[0], "");
    }
  }

  return textContent;
};

const calculateLogic = (rule, resp) => {
  if (rule.element_1["element_1"]) {
    const result1 = calculateLogic(rule.element_1, resp);
    const result2 = calculateLogic(rule.element_2, resp);

    if (rule.operator === "or") {
      return result1 || result2;
    } else if (rule.operator === "and") {
      return result1 && result2;
    }
  } else {
    const rule1 = rule.element_1;
    const rule2 = rule.element_2;

    let value1 = resp[rule1.type][rule1.value]; //resposta do usuário
    let value2 = rule2.value;

    //lógica de operação
    if (rule.operator === "equal") {
      return Array.isArray(value2)
        ? isEqual(value2, value1)
        : value1 === value2;
    } else if (rule.operator === "not-equal") {
      return Array.isArray(value2)
        ? !isEqual(value2, value1)
        : value1 !== value2;
    } else if (rule.operator === "in") {
      let thereIsAnOption = false;
      if (value1) {
        if (typeof value1 === "string") {
          if (value2.includes(value1)) {
            thereIsAnOption = true;
          }
        } else {
          value1.forEach((o) => {
            if (value2.includes(o)) {
              thereIsAnOption = true;
            }
          });
        }
      }
      return thereIsAnOption;
    } else if (rule.operator === "not-in") {
      let thereIsAnOption = false;
      if (value1) {
        if (typeof value1 === "string") {
          if (value2.includes(value1)) {
            thereIsAnOption = true;
          }
        } else {
          value1.forEach((o) => {
            if (value2.includes(o)) {
              thereIsAnOption = true;
            }
          });
        }
      }
      return !thereIsAnOption;
    } else if (rule.operator === "contains") {
      return value1 && value1 !== "" ? value1.indexOf(value2) >= 0 : false;
    } else if (rule.operator === "not-contains") {
      return value1 && value1 !== "" ? value1.indexOf(value2) <= -1 : false;
    } else if (rule.operator === "answered") {
      return value1 !== undefined && value1 !== null && value1 !== "";
    } else if (rule.operator === "not-answered") {
      return value1 === undefined || value1 === null || value1 === "";
    } else if (rule.operator === "less") {
      return parseInt(value1) < parseInt(value2);
    } else if (rule.operator === "less-equal") {
      return parseInt(value1) <= parseInt(value2);
    } else if (rule.operator === "greater") {
      return parseInt(value1) > parseInt(value2);
    } else if (rule.operator === "greater-equal") {
      return parseInt(value1) >= parseInt(value2);
    }
  }
};

/* Busca por respostas PADRÃO ou PERSISTENTES.
 * Quando uma questão já vem com valor a ser
 * marcado como resposta antes mesmo do usuário responder */
export const extractPersistAnswerOnPage = (surveyid, pageToGoID = 0) => {
  let surveyData = getStorage(surveyid, true);
  let responseUser = surveyData.user;

  pageToGoID = pageToGoID === 0 ? responseUser.currentPage : pageToGoID;

  let thisPage = surveyData.survey.pages[pageToGoID];

  thisPage.questions = thisPage.questions.map((quest) => {
    if (!responseUser.responses[quest.id] && quest?.properties?.defaultAnswer) {
      const resultTags = getTags(surveyid, quest.properties.defaultAnswer);
      if (resultTags !== "") {
        surveyData.user.responses[quest.id] = resultTags;
      }
    }
    return quest;
  });

  surveyData.survey.pages[pageToGoID].questions = thisPage.questions;

  setStorage(surveyid, surveyData, true);
};

export const validateQuestionVisibility = (surveyid) => {
  let surveyData = getStorage(surveyid, true);
  const questionLogics = surveyData.survey.logics.questions;
  const responseUser = surveyData.user;

  surveyData.survey.pages.forEach((page) => {
    var qtdQuestionActivesInPage = 0;
    page.questions = page.questions.map((quest) => {
      const logic = questionLogics?.[quest.id];

      /*
       * Resposta padrão inseridas em marcações de tags ou inseridas no ISM,
       * que preenche uma questão com um valor prévio.
       * Se uma questão for inativa ou mesmo não estiver exibida em tela ainda,
       * irá forçar o preencimento não importa em qual momento
       */
      if (
        !surveyData.user.responses[quest.id] &&
        quest?.properties?.defaultAnswer !== "" &&
        quest?.properties?.defaultAnswer !== undefined &&
        quest.properties?.persistAnswer
      ) {
        const resultTags = getTags(surveyid, quest.properties.defaultAnswer);
        if (resultTags !== "") {
          surveyData.user.responses[quest.id] = resultTags;
        }
      }

      if (logic) {
        const res = calculateLogic(logic, responseUser);
        quest.active = res;

        /*
         * Questões respondidas cotendo lógica atrelada em
         * que lógicas resultaram em falso, precisam ter suas
         * respostas excluídas
         */
        if (!res && surveyData.user.responses?.[quest.id]) {
          delete surveyData.user.responses[quest.id];
        }

        qtdQuestionActivesInPage = res
          ? (qtdQuestionActivesInPage += 1)
          : qtdQuestionActivesInPage;
      } else {
        qtdQuestionActivesInPage += 1;
      }
      return quest;
    });

    page.active = qtdQuestionActivesInPage > 0;
  });

  setStorage(surveyid, surveyData, true);
};

export const checkMergeCodes = (surveyid) => {
  let surveyData = getStorage(surveyid, true);
  let {
    survey: { language },
  } = surveyData;

  surveyData.survey.pages.forEach((page, pageIndex) => {
    page.questions = page.questions.map((quest) => {
      if (
        quest.type !== "action" && //não processa uma action
        pageIndex === surveyData.user.currentPage
      ) {
        quest.title[language] = getTags(surveyid, quest.title[language]);
      }
      return quest;
    });
  });
  setStorage(surveyid, surveyData, true);
};

/**
 * Remove todas questões que tem options com configuração oculta.
 * Retira a opção do campo de visão do usuário
 * @param {string} surveyid
 */
export const removeHideOptions = (surveyid) => {
  let surveyData = getStorage(surveyid, true);

  surveyData.survey.pages.forEach((page) => {
    page.questions = page.questions.map((quest) => {
      if (["checkbox", "radiobox", "dropdown"].includes(quest.type)) {
        let reOptions = [];

        quest.options.forEach((o) => {
          if (o?.properties) {
            if (!o.properties?.hidden) {
              reOptions.push(o);
            }
          } else {
            reOptions.push(o);
          }
        });

        quest.options = reOptions;
      }

      return quest;
    });
  });

  setStorage(surveyid, surveyData, true);
};

/**
 * Valida se usuário pode avançar para próxima página,
 * devolvendo o número para a próxima página como resposta
 * @param {string} surveyid
 * @returns {number}
 */
export const validateNextPage = (surveyid) => {
  validateQuestionVisibility(surveyid);
  hasErrors(surveyid);

  const surveyData = getStorage(surveyid, true);
  let pagePosition = surveyData.user.currentPage;
  let nextPagePosition = null;

  if (Object.keys(surveyData.errors).length <= 0) {
    surveyData.survey.pages.forEach((page, index) => {
      if (nextPagePosition === null && index > pagePosition && page.active) {
        nextPagePosition = index;
      }
    });
  }

  return nextPagePosition !== null ? nextPagePosition : pagePosition;
};

/**
 * Valida se usuário pode voltar para página anterior,
 * devolvendo o número para a próxima página como resposta
 * @param {string} surveyid
 * @returns {number}
 */
export const validatePreviousPage = (surveyid) => {
  validateQuestionVisibility(surveyid);
  const surveyData = getStorage(surveyid, true);

  let pagePositionOriginal = surveyData.user.currentPage;
  let pagePosition = surveyData.user.currentPage;

  if (pagePosition === 0) {
    return 0;
  }

  let validatePosition = () => {
    pagePosition--;

    if (pagePosition === -1) {
      pagePosition = pagePositionOriginal;
      return;
    }

    if (
      surveyData.survey.pages[pagePosition] &&
      !surveyData.survey.pages[pagePosition].active
    ) {
      validatePosition();
    }
  };

  validatePosition();

  return pagePosition;
};

/**
 * Insere no local storage as respostas do usuário para
 * campos de texto aberto que não devem renderizar enquanto se digita algo
 * @param {string} surveyid
 * @returns
 */
export const putResponse = (surveyid) => validateQuestionVisibility(surveyid);
