import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Interweave } from "interweave";
import { useParams } from "react-router-dom";

//redux
import { errors } from "../../../../../store/survey/survey.slice";

//styles
import "../../../../../assets/css/survey/input.css";

//utils
import { getStorage, setStorage } from "../../../../../utils/Storage";

const InputEmailComponent = ({ data }) => {
  const { surveyid } = useParams();
  let {
    survey: { language },
  } = getStorage(surveyid, true);

  const listError = useSelector(errors);
  const [localInput, setLocalInput] = useState("");

  const changeInput = (val) => {
    val = val !== "" ? val.replaceAll(" ", "") : val;
    let surveyData = getStorage(surveyid, true);

    if (val !== "") {
      surveyData.user.responses[data.id] = val;
    } else {
      delete surveyData.user.responses[data.id];
    }

    setStorage(surveyid, surveyData, true);
    setLocalInput(val);
  };

  useEffect(() => {
    let surveyData = getStorage(surveyid, true);
    if (surveyData.user.responses[data.id]) {
      setLocalInput(surveyData.user.responses[data.id]);
    }
    // eslint-disable-next-line
  }, []);

  const setMagicEmail = (emailValue) => {
    let surveyData = getStorage(surveyid, true);
    surveyData.user.responses[data.id] = localInput + emailValue;
    setStorage(surveyid, surveyData, true);
    setLocalInput(localInput + emailValue);
  };

  return (
    <div id="input-component" className={`email ${data?.cssClass || ""}`}>
      <div className="input-question">
        <Interweave content={data.title[language]} />
      </div>
      <div className="input-content">
        <input
          type="text"
          maxLength={data?.properties?.maxLength || 150}
          value={localInput}
          placeholder="joao.silva@email.com"
          onChange={({ target: { value } }) => changeInput(value)}
        />
        <div className="magic-buttons">
          <span onClick={() => setMagicEmail("@gmail.com")}>@gmail.com</span>
          <span onClick={() => setMagicEmail("@hotmail.com")}>
            @hotmail.com
          </span>
          <span onClick={() => setMagicEmail("@outlook.com")}>
            @outlook.com
          </span>
        </div>
      </div>
      <div className="input-error">
        {Object.keys(listError).includes(data.id + "")
          ? listError[data.id]
          : ""}
      </div>
    </div>
  );
};

export default InputEmailComponent;
