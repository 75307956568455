/**
 * Gera uma string aleatoria
 * @param {string} qtd - quantidade de caracteres a serem retornados
 * @returns
 */
export const randomId = (qtd) => {
  qtd = qtd || 10;

  var result = "";
  var characters = `ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789`;
  var charactersLength = characters.length;
  for (var i = 0; i < qtd; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
};

/**
 * Descobre se um hexadecimal é escuro ou claro, com base na sua luminosidade
 * @param {string} hexadecimal - (String) #000000
 * @returns {string}
 */
export const getLuminosity = (hexadecimal) => {
  var nib = hexadecimal.split("");
  var r = parseInt(nib[1] + nib[2], 16);
  var g = parseInt(nib[3] + nib[4], 16);
  var b = parseInt(nib[5] + nib[6], 16);
  var luminosity = (r * 299 + g * 587 + b * 114) / 1000;
  return luminosity < 127.5 ? "dark" : "light";
};

/**
 * Valida um CPF. A fonte do código é do site da receita federal
 * @param {String} strCPF
 * @returns {boolean}
 */
export const validateCPF = (strCPF) => {
  let Soma = 0;
  let Resto;

  strCPF = String(strCPF).replace(/[^\d]/g, "");

  if (strCPF.length !== 11) return false;

  if (
    [
      "00000000000",
      "11111111111",
      "22222222222",
      "33333333333",
      "44444444444",
      "55555555555",
      "66666666666",
      "77777777777",
      "88888888888",
      "99999999999",
    ].indexOf(strCPF) !== -1
  )
    return false;

  for (let i = 1; i <= 9; i++)
    Soma = Soma + parseInt(strCPF.substring(i - 1, i)) * (11 - i);
  Resto = (Soma * 10) % 11;

  if (Resto === 10 || Resto === 11) Resto = 0;
  if (Resto !== parseInt(strCPF.substring(9, 10))) return false;

  Soma = 0;

  for (let i = 1; i <= 10; i++)
    Soma = Soma + parseInt(strCPF.substring(i - 1, i)) * (12 - i);
  Resto = (Soma * 10) % 11;

  if (Resto === 10 || Resto === 11) Resto = 0;
  if (Resto !== parseInt(strCPF.substring(10, 11))) return false;

  return true;
};

export const getURLSearchParams = () => {
  if (window.location.search !== "") {
    const params = new URLSearchParams(window.location.search);
    const encodedParams = new URLSearchParams();
    params.forEach((value, key) => {
      encodedParams.append(key, encodeURIComponent(value));
    });
    return "?" + encodedParams.toString();
  }

  return "";
};
