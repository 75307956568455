import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Interweave } from "interweave";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";

//redux
import { errors } from "../../../../store/survey/survey.slice";
import { checkIfUpdate } from "../../../../store/survey/survey.actions";
import { putResponse } from "../../../Survey/validates";

//styles
import "../../../../assets/css/survey/checkbox.css";

//utils
import { getStorage, setStorage } from "../../../../utils/Storage";

//compoenent
import CheckBoxInput from "./CheckBoxInput";

const CheckboxComponent = ({ data }) => {
  const { surveyid } = useParams();
  let {
    survey: { language },
  } = getStorage(surveyid, true);

  const dispatch = useDispatch();
  const listError = useSelector(errors);
  let [localInputVals, setLocalInputVals] = useState({});

  let designType = () => {
    let classDesign = "checkbox";

    if (data.properties?.check) {
      classDesign = "check";
    } else if (data.properties?.pill) {
      classDesign = "pill";
    } else if (data.properties?.pillCheck) {
      classDesign = "pill-check";
    } else if (data.properties?.full) {
      classDesign = "full";
    }

    return classDesign;
  };

  let designCustom = () => {
    let designCustom = [];

    if (data.properties?.shadow) {
      designCustom.push("shadow");
    }

    return designCustom.join(" ");
  };

  useEffect(() => {
    getInputText();
    // eslint-disable-next-line
  }, []);

  const selectCheckbox = (keyValue, properties) => {
    let surveyData = getStorage(surveyid, true);
    let checkboxSelectedCopy = surveyData.user.responses[data.id] || [];
    if (properties) {
      if (properties?.type === "other") {
        if (checkboxSelectedCopy && checkTypeSelectdItem(keyValue)) {
          let newArray = checkboxSelectedCopy.filter(
            (value) => value.indexOf(keyValue) <= -1
          );
          checkboxSelectedCopy = newArray;
        } else {
          if (
            data?.properties &&
            data.properties?.maxLength &&
            Object.keys(localInputVals).length >= data.properties.maxLength
          ) {
            toast.info(
              `Permitido marcar apenas ${data.properties.maxLength} opções. 
              (Only ${data.properties.maxLength} options allowed)`,
              { autoClose: 2000 }
            );
            return;
          }
          checkboxSelectedCopy.push(keyValue + "||");
        }
      } else if (properties.type === "exclusive") {
        if (checkboxSelectedCopy && checkTypeSelectdItem(keyValue)) {
          checkboxSelectedCopy = [];
        } else {
          checkboxSelectedCopy = [keyValue];
        }
      }
    } else {
      if (checkboxSelectedCopy && checkboxSelectedCopy.includes(keyValue)) {
        let newArray = checkboxSelectedCopy.filter(
          (value) => value !== keyValue
        );
        checkboxSelectedCopy = newArray;
      } else {
        if (
          data.properties &&
          data.properties.maxLength &&
          Object.keys(localInputVals).length >= data.properties.maxLength
        ) {
          toast.info(
            `Permitido marcar apenas ${data.properties.maxLength} opções. (Only ${data.properties.maxLength} options allowed)`
          );
          return;
        }
        checkboxSelectedCopy.push(keyValue);
      }

      //Se alguma opção estiver marcada como exclusiva, só ela será exibida
      data.options.forEach((opt) => {
        if (opt.properties && opt.properties.type === "exclusive") {
          if (checkboxSelectedCopy.includes(opt.value)) {
            checkboxSelectedCopy = [opt.value];
          }
        }
      });
    }

    if (checkboxSelectedCopy.length > 0) {
      surveyData.user.responses[data.id] = checkboxSelectedCopy;
    } else {
      delete surveyData.user.responses[data.id];
    }

    setStorage(surveyid, surveyData, true);
    setLocalInputVals(checkboxSelectedCopy);
    putResponse(surveyid);
    dispatch(checkIfUpdate(surveyid, data.id));
  };

  const checkTypeSelectdItem = (key) => {
    let checked = false;
    let surveyData = getStorage(surveyid, true);
    if (surveyData.user.responses[data.id]) {
      surveyData.user.responses[data.id].forEach((item) => {
        if (item.indexOf(key) >= 0) {
          checked = true;
        }
      });
    }
    return checked;
  };

  const getInputText = () => {
    let surveyData = getStorage(surveyid, true);
    data.options.forEach((opt, i) => {
      let inputValue = "";
      if (surveyData.user.responses[data.id]) {
        surveyData.user.responses[data.id].forEach((item) => {
          if (item.indexOf(opt.value) >= 0) {
            let splitItem = item.split("||");
            inputValue = splitItem[1];
          }
        });

        setLocalInputVals({
          ...localInputVals,
          [`${data.id}${i}0`]: inputValue,
        });
      }
    });
  };

  const CheckboxDefault = () => {
    return (
      <>
        {data.options &&
          data.options.map((item, i) => {
            return (
              <div
                key={`checkbox-option-${data.id}-${i}`}
                className="checkbox-option"
              >
                <input
                  type="checkbox"
                  id={`checkbox-${data.id}-${i}`}
                  name={`checkbox-${data.id}`}
                  className="checkbox-item"
                  onChange={() => selectCheckbox(item.value, item.properties)}
                  checked={checkTypeSelectdItem(item.value) ? true : false}
                />
                <label htmlFor={`checkbox-${data.id}-${i}`}>
                  {item.label[language]}
                </label>
                {item?.properties &&
                  item.properties?.type === "other" &&
                  checkTypeSelectdItem(item.value) && (
                    <CheckBoxInput
                      questionId={data.id}
                      radioItem={item}
                      idStorage={`${data.id}-${i}-0`}
                    />
                  )}
              </div>
            );
          })}
      </>
    );
  };

  const CheckboxCustom = () => {
    return (
      <>
        {data.options &&
          data.options.map((item, i) => {
            return (
              <React.Fragment key={`checkbox-option-${data.id}-${i}`}>
                <div
                  className={`checkbox-option ${
                    checkTypeSelectdItem(item.value) ? "selected" : ""
                  }`}
                  onClick={() => selectCheckbox(item.value, item.properties)}
                >
                  <span>{item.label[language]}</span>
                </div>
                {item?.properties &&
                  item.properties?.type === "other" &&
                  checkTypeSelectdItem(item.value) && (
                    <CheckBoxInput
                      questionId={data.id}
                      radioItem={item}
                      idStorage={`${data.id}-${i}-0`}
                    />
                  )}
              </React.Fragment>
            );
          })}
      </>
    );
  };

  const GenerateCheckbox = () => {
    switch (designType()) {
      case "checkbox":
      case "check":
      case "pill":
      case "pill-check":
        return <CheckboxDefault />;
      case "full":
        return <CheckboxCustom />;
      default:
        return <></>;
    }
  };

  return (
    <div
      id="checkbox-component"
      className={`${designType()} ${designCustom()} ${data?.cssClass || ""}`}
    >
      <div className="checkbox-title">
        <Interweave content={data.title[language]} />
      </div>
      <div className="checkbox-content">
        <GenerateCheckbox />
      </div>
      <div className="checkbox-error">
        {Object.keys(listError).includes(data.id + "")
          ? listError[data.id]
          : ""}
      </div>
    </div>
  );
};

export default CheckboxComponent;
