import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Interweave } from "interweave";
import { useParams } from "react-router-dom";

//redux
import { errors } from "../../../../../store/survey/survey.slice";

//styles
import "../../../../../assets/css/survey/input.css";

//utils
import { getStorage, setStorage } from "../../../../../utils/Storage";

const InputDateTimeComponent = ({ data }) => {
  const { surveyid } = useParams();
  let {
    survey: { language },
  } = getStorage(surveyid, true);

  const listError = useSelector(errors);
  const [localInput, setLocalInput] = useState("");

  let designCustom = () => {
    let classDesign = "date";

    if (data.properties) {
      if (data.properties.date) {
        classDesign = "date";
      } else if (data.properties.time) {
        classDesign = "time";
      } else if (data.properties.datetime) {
        classDesign = "datetime-local";
      }
    }

    return classDesign;
  };

  const changeInput = (val) => {
    let surveyData = getStorage(surveyid, true);

    if (val !== "") {
      surveyData.user.responses[data.id] = val;
    } else {
      delete surveyData.user.responses[data.id];
    }

    setStorage(surveyid, surveyData, true);
    setLocalInput(val);
  };

  useEffect(() => {
    let surveyData = getStorage(surveyid, true);
    if (surveyData.user.responses[data.id]) {
      setLocalInput(surveyData.user.responses[data.id]);
    }
    // eslint-disable-next-line
  }, []);

  return (
    <div
      id="input-component"
      className={`${designCustom()} ${designCustom()}-${data.id} ${
        data?.cssClass || ""
      }`}
    >
      <div className="input-question">
        <Interweave content={data.title[language]} />
      </div>
      <div className="input-content">
        <input
          type={designCustom()}
          maxLength={data?.properties?.maxLength || 17}
          value={localInput}
          onChange={({ target: { value } }) => changeInput(value)}
        />
      </div>
      <div className="input-error">
        {Object.keys(listError).includes(data.id + "")
          ? listError[data.id]
          : ""}
      </div>
    </div>
  );
};

export default InputDateTimeComponent;
