import moment from "moment";

//redux
import {
  setUpdate,
  setErrorAPI,
  setLoading,
  setKioskiMode,
  setActionResponse,
  setSurveyData,
} from "../../store/survey/survey.slice";
import {
  getSurveyAPI,
  getSurveyTestAPI,
  receiveResponseAPI,
  actionDispatchAPI,
  getSurveyTimerAPI,
} from "../../store/survey/survey.api";
import {
  validateQuestionVisibility,
  extractPersistAnswerOnPage,
  checkMergeCodes,
  removeHideOptions,
} from "../../pages/Survey/validates";

//utils
import { randomId } from "../../utils/String";
import { shuffleArrayAndFixed } from "../../utils/Array";
import { getStorage, setStorage } from "../../utils/Storage";

const generateResponseId = (surveyid, timer) => {
  const surveyData = getStorage(surveyid, true);

  //Se cálculo de dias for mais que o timer, gera um id novo
  const dayTime = surveyData.time;
  const dayTimeNow = moment().unix();
  const calcTimer = dayTimeNow - dayTime;

  if (calcTimer > timer) {
    setStorage(
      surveyid,
      {
        ...surveyData,
        time: moment().unix(),
      },
      true
    );
    return randomId();
  }

  //SE NÃO MUDAR O DIA, SEGUE O FLUXO PADRÃO
  const user = surveyData.user;
  const survey = surveyData.survey;

  if (survey) {
    if (user && user.linkid && user.linkid !== surveyid) {
      setStorage(
        surveyid,
        {
          ...surveyData,
          time: moment().unix(),
        },
        true
      );
      return randomId();
    } else {
      return user.id;
    }
  } else {
    return randomId();
  }
};

const shuffleCheckBoxAndRadioBox = (survey) => {
  survey.pages.map((page) => {
    page.questions.map((quest) => {
      if (quest.type === "checkbox" || quest.type === "radiobox") {
        quest.options = shuffleArrayAndFixed(quest.options);
      }
      return quest;
    });
    return page;
  });
  return survey;
};

const getQuerySearchs = (urls) => {
  let urlsCopy = { ...urls };

  let queryString = window.location.search.replace("?", "");
  let qReplace = queryString.replace("%20", " ");
  const qReplaceSplit = qReplace.split("&");

  qReplaceSplit.forEach((param) => {
    const paramSplit = param.split("=");
    urlsCopy[paramSplit[0]] = paramSplit[1];
  });

  return urlsCopy;
};

/**
 * Carrega os dados da pesquisa
 * @param {Object}
 * @returns
 */
export const initDataSurvey = ({ surveyid, responseid }) => {
  return (dispatch) => {
    const getTimer = getSurveyTimerAPI(surveyid);

    getTimer
      .then((dataTimer) => {
        const { timer } = dataTimer;

        const responseIdGenerated =
          responseid === "" || responseid === undefined || responseid === null
            ? generateResponseId(surveyid, timer)
            : responseid;

        setTimeout(() => {
          const path = window.location.pathname;
          const urlToAPI =
            path.indexOf("test") >= 0
              ? getSurveyTestAPI({
                  surveyid,
                  id: responseIdGenerated,
                })
              : getSurveyAPI({ surveyid, id: responseIdGenerated });

          urlToAPI
            .then((dataAPI) => {
              let { status, message, responses: dataUser, survey } = dataAPI;

              // Se a pesquisa exisitir
              if (status) {
                let {
                  id,
                  responses,
                  currentPage,
                  progress,
                  created_at,
                  url,
                  contact,
                  linkid,
                  test,
                } = dataUser;

                //adiciona js ou css de links terceiros no <head> do site
                if (survey.design?.scriptsHead || survey.design?.stylesHead) {
                  let scriptsHead = survey.design?.scriptsHead;
                  let stylesHead = survey.design?.stylesHead;

                  if (scriptsHead && scriptsHead.length > 0) {
                    let scriptTag = document.createElement("script");
                    scriptsHead.forEach((scriptLink) => {
                      scriptTag.src = scriptLink;
                      scriptTag.type = "text/javascript";
                      document.head.appendChild(scriptTag);
                    });
                  }

                  if (stylesHead && stylesHead.length > 0) {
                    let styleTag = document.createElement("link");
                    stylesHead.forEach((styleLink) => {
                      styleTag.href = styleLink;
                      styleTag.rel = "stylesheet";
                      document.head.appendChild(styleTag);
                    });
                  }
                }

                //adiciona js ou css puro no <head> do site
                if (survey.design?.scripts || survey.design?.styles) {
                  let hasInovyoScripts =
                    document.getElementById("inovyo-scripts");
                  let hasInovyoStyles =
                    document.getElementById("inovyo-styles");
                  if (!hasInovyoScripts && survey.design?.scripts) {
                    let script = document.createElement("script");
                    script.innerHTML = survey.design.scripts;
                    script.id = "inovyo-scripts";
                    script.type = "text/javascript";
                    document.head.appendChild(script);
                  }
                  if (!hasInovyoStyles && survey.design?.styles) {
                    let style = document.createElement("style");
                    style.innerHTML = survey.design.styles;
                    style.innerHTML = style.innerHTML.replaceAll("\n", "");
                    style.innerHTML = style.innerHTML.replaceAll("\n\t", "");
                    style.innerHTML = style.innerHTML.replaceAll("\n\r", "");
                    style.id = "inovyo-styles";
                    document.head.appendChild(style);
                  }
                }

                survey = shuffleCheckBoxAndRadioBox(survey);

                //define os itens estáticos de url
                url = getQuerySearchs(url);

                const dataUserJson = {
                  id,
                  responses,
                  currentPage,
                  progress,
                  created_at,
                  linkid,
                  url,
                  contact,
                  test,
                };

                if (
                  (url?.__kioski &&
                    url.__kioski !== "" &&
                    url.__kioski === "on") ||
                  url?.kioski
                ) {
                  if (url?.kioski) {
                    if (!url.kioski?.sleep) {
                      url.kioski["sleep"] = 60;
                    }

                    if (!url.kioski?.restart) {
                      url.kioski["restart"] = 10;
                    }
                  } else {
                    url.kioski = { sleep: 60, restart: 10 };
                  }

                  survey.kioski = url.kioski;
                  dispatch(setKioskiMode(true));
                }

                const surveyData = getStorage(surveyid, true);
                setStorage(
                  surveyid,
                  {
                    survey,
                    errors: "",
                    inputs: {},
                    user: dataUserJson,
                    time: surveyData.time,
                  },
                  true
                );

                /* 
                  Reposiciona a currentPage (página inicial de carregamento)
                  quando alguma página foi inativada devido a lógica
                */
                const rebuildDefaultEnviroment = (surveyid) => {
                  let surveyDataCopy = getStorage(surveyid, true);
                  let currentPg = surveyDataCopy.user.currentPage;

                  const validateActiveCurrentPage = () => {
                    if (
                      surveyDataCopy.survey.pages[currentPg] &&
                      !surveyDataCopy.survey.pages[currentPg].active
                    ) {
                      currentPg++;
                      validateActiveCurrentPage();
                    }
                  };

                  validateActiveCurrentPage();
                  surveyDataCopy.user.currentPage = currentPg;

                  dispatch(setSurveyData(surveyDataCopy.survey));
                  setStorage(surveyid, surveyDataCopy, true);
                };

                setTimeout(() => {
                  removeHideOptions(surveyid);
                  validateQuestionVisibility(surveyid);
                  rebuildDefaultEnviroment(surveyid);
                  extractPersistAnswerOnPage(surveyid);
                  checkMergeCodes(surveyid);
                  dispatch(setUpdate());
                  dispatch(setLoading(false));
                }, 1000);
              } else {
                dispatch(setErrorAPI(message));
                dispatch(setLoading(false));
              }
            })
            .catch(() => {
              dispatch(
                setErrorAPI(
                  "Você está sem internet ou houve uma falha de comunicação!"
                )
              );
              dispatch(setLoading(false));
            });
        }, 1000);
      })
      .catch(() => {
        dispatch(
          setErrorAPI(
            "Você está sem internet ou houve uma falha de comunicação!"
          )
        );
        dispatch(setLoading(false));
      });
  };
};

/**
 * Envia os daods para a API
 * @param {*} dataToSend
 * @returns
 */
export const sendDataToDB = (dataToSend) => {
  return () => {
    receiveResponseAPI(dataToSend);
  };
};

/**
 * Analisa se a questão respondida tem lógica atrelada a ela,
 * se houver; atualiza a página para exibir a lógica
 * @param {Number} questionId Id da questão
 * @returns
 */
export const checkIfUpdate = (surveyid, questionId) => {
  return (dispatch) => {
    let surveyData = getStorage(surveyid, true);
    let existLogic = false;

    const deepLogic = (el) => {
      if (el.lvl === 0) {
        if (el.element_1.value === questionId) {
          return true;
        } else {
          return false;
        }
      } else {
        const res1 = deepLogic(el.element_1);
        if (res1) {
          return true;
        }
        const res2 = deepLogic(el.element_2);
        if (res2) {
          return true;
        } else {
          return false;
        }
      }
    };

    for (var i in surveyData.survey.logics.questions) {
      const itemLogic = surveyData.survey.logics.questions[i];
      if (Object.keys(itemLogic).length > 0 && deepLogic(itemLogic)) {
        existLogic = true;
      }
    }

    if (existLogic) {
      validateQuestionVisibility(surveyid);
      dispatch(setUpdate());
    }
  };
};

export const actionDispatch = (dataAction) => {
  return (dispatch) => {
    actionDispatchAPI(dataAction)
      .then((data) => {
        dispatch(setActionResponse(data.response));
      })
      .catch(() => {
        dispatch(setActionResponse(false));
      });
  };
};
