import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Interweave } from "interweave";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";

//redux
import { errors, userResponses } from "../../../../store/survey/survey.slice";
import { checkIfUpdate } from "../../../../store/survey/survey.actions";
import { putResponse } from "../../../Survey/validates";

//styles
import "../../../../assets/css/survey/auto-complete.css";

//utils
import { getStorage, setStorage } from "../../../../utils/Storage";

const AutoCompleteComponent = ({ data }) => {
  const { surveyid } = useParams();

  let {
    survey: { language },
  } = getStorage(surveyid, true);
  const dispatch = useDispatch();

  const listError = useSelector(errors);
  const userSurvey = useSelector(userResponses);
  const [autoComplete, setAutoComplete] = useState("");
  const [optionsComplete, setOptionsComplete] = useState([]);
  const [activeTray, setActiveTray] = useState(false);

  useEffect(() => {
    setOptionsComplete(data.options);
    if (userSurvey.responses[data.id]) {
      setAutoComplete(userSurvey.responses[data.id]);
    }
    // eslint-disable-next-line
  }, []);

  const validateOptionTyped = () => {
    let surveyData = getStorage(surveyid, true);
    let typedText = surveyData.user.responses[data.id] || "";

    if (data?.properties && data.properties?.onlyOptions && typedText !== "") {
      if (!data.options.includes(typedText)) {
        delete surveyData.user.responses[data.id];
        setStorage(surveyid, surveyData, true);
        setOptionsComplete(data.options);
        setAutoComplete("");
        putResponse(surveyid);
        toast.info(`Selecione uma opção da lista`);
      } else {
        setAutoComplete(typedText);
      }
    } else {
      setAutoComplete(typedText);
    }
  };

  /**
   * Filtra os valores a serem exibidos e preenchidos
   * @param {string} value valor digitado ou selecionado
   * @param {boolean} clicked se foi uma ação baseada em clique ou digitou
   * @param {boolean} clear limpa o campo de texto
   */
  const filterOptions = (value, clicked, clear) => {
    value = clicked ? value : data.properties?.notType ? "" : value;

    let filtered =
      value !== ""
        ? data.options.filter((val) => val.toUpperCase().indexOf(value) >= 0)
        : data.options;

    setOptionsComplete(filtered);
    setAutoComplete(value);

    if (clicked || value === "") {
      let surveyData = getStorage(surveyid, true);

      if (clear || value === "") {
        delete surveyData.user.responses[data.id];
      } else {
        surveyData.user.responses[data.id] = value;
      }

      setStorage(surveyid, surveyData, true);
      putResponse(surveyid);
      dispatch(checkIfUpdate(surveyid, data.id));
    }
  };

  const resetItem = () => {
    filterOptions("", true, true);
    document.getElementById("complete" + data.id).blur();
  };

  return (
    <div
      id="auto-complete-component"
      className={`auto-complete ${data?.cssClass || ""}`}
    >
      <div className="auto-complete-title">
        <Interweave content={data.title[language]} />{" "}
        {data.require && data.properties && data.properties.showAsterisk && (
          <span className="require">
            <sup>*</sup>
          </span>
        )}
      </div>
      <div className="auto-complete-content">
        <input
          id={"complete" + data.id}
          type="text"
          maxLength="255"
          value={autoComplete}
          autoComplete="off"
          onFocus={() => setActiveTray(true)}
          onBlur={() => {
            setTimeout(() => {
              setActiveTray(false);
              validateOptionTyped();
            }, 200);
          }}
          onChange={({ target: { value } }) =>
            filterOptions(value.toUpperCase(), false, false)
          }
        />
        {autoComplete.length > 0 && (
          <div className="close-tray-box" onClick={() => resetItem()}>
            x
          </div>
        )}
        {activeTray && (
          <div className="tray-box">
            {(!data.properties || !data.properties.onlyOptions) &&
              autoComplete !== "" && (
                <div
                  className="tray-item"
                  onClick={() => filterOptions(autoComplete, true, false)}
                >
                  "{autoComplete}"
                </div>
              )}

            {optionsComplete.length > 0 &&
              optionsComplete.map((item, index) => {
                return (
                  <div
                    className="tray-item"
                    key={`${data.id}-${index}`}
                    onClick={() => filterOptions(item, true)}
                  >
                    {item}
                  </div>
                );
              })}
          </div>
        )}
      </div>
      <div className="auto-complete-error">
        {Object.keys(listError).includes(data.id + "")
          ? listError[data.id]
          : ""}
      </div>
    </div>
  );
};

export default AutoCompleteComponent;
